module.exports={
"label.account.name":`First Name`,
"label.account.copy":`Please copy the random verification code in the email, and click the link to change the password in the next step.`,
"label.account.phone.length":`The mobile number requires at least 7 characters`,
"label.account.Start.trial":`GET STRTED`,
"label.account.employees1000":`1000+ employees`,
"label.account.other.Ways":`Other login methods`,
"label.account.emailsync.button.cancel":`Cancel`,
"label.account.rule":`Consists of 8-16 digits and letters.`,
"label.account.password.update.success":`Password changed successfully.`,
"label.account.describe":`CloudCC focuses on the digital personalization, analytical intelligence, and global connectivity, and is customized on-demand along with enterprise business development.`,
"label.account.enter.Emailbox":`Please enter your email address.`,
"label.account.password":`Password`,
"label.account.problem":`Login failed.`,
"label.account.Complete.reset":`Email. Please enter the verification code to complete the password reset.`,
"label.account.crm.used":`CRM used by listed companies`,
"label.account.report.loading":`Loading desperately···`,
"label.account.notcustomer":`No account?`,
"label.account.employees501":`501-1000 employees`,
"label.account.title.operationManager":`Operation Manager`,
"label.account.Preset":`Pre-set project management tools`,
"label.account.company":`Company`,
"label.account.come":`Welcome to Cloudcc Customer Success Platform. Our new lightning platform provides you with the fastest and most complete solution, enabling you to do everything with your customer at the center.  `,
"label.account.input.password":`Please enter your password.`,
"label.account.emailobject.emaildetail.button.back":`Back`,
"label.account.title.itManager":`IT Manager`,
"label.account.Modified.successfully":`Password change successfully. Please click login again to return to the login page.`,
"label.account.only11":`Please enter at least 7 digits.`,
"label.account.signin":`Login`,
"label.account.register":`Register to Get`,
"label.account.adopt":`Verification passed!`,
"label.account.title.hrManager":`Marketing/HR Manager`,
"label.account.input.usernames":`Please enter your username.`,
"label.account.only.Numbers":`Phone number can only enter numbers.`,
"label.account.title.salesManager":`Sales Manager`,
"label.account.email":`Email`,
"label.account.click":`Click`,
"label.account.and":`and`,
"label.account.mobile":`Phone`,
"label.account.Privacy.policy":`Privacy Policy`,
"label.account.password.isnull":`Password cannot be empty.`,
"label.account.employees101":`101-500 employees`,
"label.account.remember.name":`Remember username`,
"label.account.Select.country":`Please select your country/region.`,
"label.account.distinguish":`The password is case-sensitive.`,
"label.account.fillMobile":`Please enter your phone number.`,
"label.account.Preconfigured":`Pre-set reports and dashboards`,
"label.account.Select.job":`Please select your position.`,
"label.account.employees21":`21-100 employees`,
"label.account.username":`Username`,
"label.account.Submit":`Submit`,
"label.account.confirm":`Account Confirmation`,
"label.account.contact":`Contact Us`,
"label.account.country":`Country`,
"label.account.username.isnull":`Username cannot be empty.`,
"label.account.forget.password":`Forgot password?`,
"label.account.Loading":`Loading`,
"label.account.goon":`Continue`,
"label.account.Register.trial":`Sign Up For A Free Trial`,
"label.account.title.other":`Other`,
"label.account.surname":`Last Name`,
"label.account.Fail":`Verification failed, please try again.`,
"label.account.Terms.service":`Terms of Service`,
"label.account.Select.staff":`Please select the number of employees.`,
"label.account.username.enter":`Please enter your last name.`,
"label.account.trial":`Free Trial`,
"label.account.employees1":`1-20 employees`,
"label.account.Quick.registration":`or access quickly`,
"label.account.tabpage.ok":`Comfirm`,
"label.account.normal.reregister":`Login again`,
"label.account.batchadd.newbatchpage.save.em":`Email format is incorrect.`,
"label.account..user.title":`Job Title`,
"label.account.No.credit.card.required":`No credit card required.`,
"label.account.enterprise.Wechat":`WeCom`,
"label.account.sample.data":`Preload sample data`,
"label.account.title.hobbies":`Hobbies`,
"label.account.platform":`New Intelligent, Mobile And Digital CRM Platform`,
"label.account.sent":`Email sent`,
"label.account.Confirm.password":`Please confirm your password.`,
"label.account.fill":`Please fill in all.`,
"label.account.user.resetpw":`Reset Password`,
"label.account.Spell":`Drag the slider to complete the puzzle.`,
"label.account.identifying.code":`Please enter the verification code.`,
"label.account.staff":`Employees`,
"label.account.welcome":`Welcome to CloudCC`,
"label.account.online.guidance":`Online consulting and instruction`,
"label.account.login.CloudCC":`Login to CloudCC`,
"label.account.Company.name":`Please enter your company name.`,
"label.account.title.generalManager":`General Manager`,
"label.account.enter.user":`To reset your password, please enter your CloudCC username.`,
"label.account.sent.code":`Verification code has been sent to`,
"label.account.pw.error.text":`The two passwords you entered did not match.`,
"label.account.title.customerManager":`Customer Service Manager`,
"label.account.label.captcha":`Verification Code`,
"label.account.Trial.days":`Sign Up For A 30-Day Free Trial`,
"label.account.please.enter.name":`Please enter your first name.`,
"label.account.inspect":`If you do not receive the verification code confirmation email, please check the spam.`,
"label.account.User.rule":`The username is in the form of an email address.`,
"label.account.go.Login":`Already have an account?`,
"label.account.Reading.consent":`I agree to the`,
"label.account.Change.Password2":`Change Password`,
"label.account.Loginby":`Loginby`,
"label.account.apply.partner":`Apply to become a CloudCC Partner`,
"label.account.MobileLogin":`Mobile`,
"label.account.Registration":`Registering···`,
"label.account.Italy":`Italy`,
"label.account.view.details":`View detail`,
"label.account.accept":`Please read and accept the Terms of Service and Privacy Policy`,
"label.account.China.Mainland":`Chinese Mainland`,
"label.account.NewPassword":`Please enter the new password`,
"label.account.Mobile.not.empty":`The mobile number cannot be empty`,
"label.account.Please.select":`Please choose`,
"label.account.market.introduce":`Unified platform of marketing channels helps you win more leads`,
"label.account.IOS":`Scan to download iOS app`,
"label.account.failsend":`Failed to send`,
"label.account.UpperLowerlAphanumeric.character":`Uppercase and lowercase letters, numbers, and aymbols are requried`,
"label.account.New.Password1":`New Password`,
"label.account.succSend":`An email has been sent to you. Please copy the verification code in the email and reset the password.`,
"label.account.Account.mobile":`Account or mobile number`,
"label.account.Login.consent":`By login, you agree to`,
"label.account.fillAreaCode":`Please enter the area code`,
"label.account.active.login":`CloudCC Mobile has been installed, please login directly after activation.`,
"label.account.jp":`Japan`,
"label.account.partner.success1":`You have successfully registered as a CloudCC partner.`,
"label.account.Reset":`Reset`,
"label.account.no.user.info":`Unable to get user's information`,
"label.account.EnterOldPass":`Please enter the old password`,
"label.account.Germany":`Germany`,
"label.account.title.content":`Sign up for CloudCC | 30-day free trial – Try now!`,
"label.account.Taiwan":`Taiwan, China`,
"label.account.China.Station":`China`,
"label.account.sale.introduce":`Sales automation helps you win more deals`,
"label.account.Back":`Previous Step`,
"label.account.market":`Marketing Cloud`,
"label.account.section.company":`Company Details`,
"label.account.check.email":`our account executive will reach out to you in a while, please check your email in time`,
"label.account.characters":`5 characters`,
"label.account.International":`International`,
"label.account.britain":`United Kingdom`,
"label.account.reg.fail.msg":`Sign up failed. Please try again later.`,
"label.account.success.reg":`you have successfully registered`,
"label.account.company.url":`Website`,
"label.account.CannotBeEmpty":`Cannot be empty`,
"label.account.phone.note":`Control company's business anytime, anywhere with CloudCC CRM Mobile`,
"label.account.second":`{0} seconds later`,
"label.account.Change.Password":`Change Your Password`,
"label.account.Netherlands":`Netherlands`,
"label.account.pleaseAgree":`Please accept and agree to the Terms of Service and Privacy Policy`,
"label.account.copyright":`CloudCC Inc. All Rights Reserved`,
"label.account.letter":`1 letter`,
"label.account.remember":`Now I remember`,
"label.account.accountNum":`Account`,
"label.account.code.not.empty":`The captcha cannot be empty`,
"label.account.Spain":`Spain`,
"label.account.Alphanumeric":`A mix of letters and numbers is required`,
"label.account.obtain":`Acquire`,
"label.account.company.txt":`Please add your name, if you don't have a registered business entity`,
"label.account.con.browser":`Continue in browser`,
"label.account.Android":`Scan to download Android app`,
"label.account.title":`CloudCC Free Trial - CloudCC.com`,
"label.account.partner.success2":`Our will reach out to you in a while.`,
"label.account.Macao":`Macao, China`,
"label.account.Brazil":`Brazil`,
"label.account.service.introduce":`Seamless service process improves the effectiveness of response`,
"label.account.codeFailure":`Invalid verification code`,
"label.account.AccountLogin":`Account`,
"label.account.pswComplexReqRules":`The minimum length of password is {0}, and {1}`,
"label.account.UpperLowerlAphanumeric":`Uppercase and lowercase letters, and number are requried`,
"label.account.active.username":`Please go to email {email} to activate your account and initialize your password.`,
"label.account.rememberPassword":`Remember password`,
"label.account.HongKong":`Hon Kong, China`,
"label.account.oldPass":`Old Password`,
"label.account.Data.storage":`Your data will be stored in the {0} data center.`,
"label.account.number":`1 number`,
"label.account.Poland":`Poland`,
"label.account.service":`Service Cloud`,
"label.account.Incorrect.format":`Incorrect format or number, please reenter`,
"label.account.remember.status":`Remember me`,
"label.account.sale":`Sales Cloud`,
"label.account.No.account.binding":`No account is bound. Please contact the administrator`,
"label.account.Russia":`Russia`,
"label.account.France":`France`,
"label.account.partner.content":`CloudCC is a global company that is now recruiting partners to explore solutions across industries. Come and join our affiliate program!`,
"label.account.reg.phone.already":`This organization already exists for this phone. Log in or use a different phone to sign up.`,
"label.account.Confirm":`Confirm New Password`,
"label.account.VerificationLogin":`Verify Code`,
"label.account.USA":`United States`,
"label.account.mobile.num":`Please enter the mobile number`,
"label.account.MinLength":`The minimum length of password is `,
"label.account.change.your.password":`Please change the password now in view of security`,
"label.account.new.password":`Enter a new password for {0}. Make sure to include at least:`,
"label.account.website.enter":`Please enter the official website address`,
"label.account.pswComplexIsZero":`The minimum length of password is {0}`
}